import { LinkMap, Reference } from '@oresundsbron/api';
import { Link } from '@oresundsbron/api/src/contentful/Links';
import { RedirectSearchResponse } from '@oresundsbron/api/src/types/contentful/Redirect';
import dynamic from 'next/dynamic';
import { ParsedUrlQuery } from 'querystring';
import { contentRequest } from './api/server';
const API_URL = process.env.API_URL || '';

export const allTranslationKeys = [
  'club',
  'privateSignUp',
  'businessSignUp',
  'commuterSignUp',
  'businessCommuterSignUp',
  'contact',
  'onlineTicket',
  'bridgeStatus',
  'error',
  'common',
  'account',
];

export const findRedirectPage = async (
  params: ParsedUrlQuery | undefined,
  locale: string,
  defaultLocale?: string
) => {
  try {
    if (!params?.slug) return;
    const urlSlug = Array.isArray(params?.slug)
      ? [...params?.slug]
      : [params?.slug];
    urlSlug.unshift(locale);
    const url = `/${urlSlug.join('/')}`;
    const response = await contentRequest<RedirectSearchResponse>(
      '/redirect',
      `fromUrl=${url}&defaultLocale=${defaultLocale}`
    );
    return response.redirect;
  } catch (e) {
    console.error(e);
    return;
  }
};

export const findCurrentPage = (
  allLinks: Record<string, LinkMap>,
  params: ParsedUrlQuery | undefined,
  locale: string
): [string | undefined, Link | undefined] => {
  let id: string | undefined;
  let returnLink: Link | undefined;
  Object.keys(allLinks[locale]).some((subKey) => {
    const subObj = allLinks[locale][subKey];
    if (
      subObj.locale === locale &&
      JSON.stringify(subObj.slugs) === JSON.stringify(params?.slug)
    ) {
      id = subKey;
      returnLink = subObj;
      return true;
    }
    return false;
  });
  return [id, returnLink];
};

export const findParentLinkRef = (
  currentPageLink: Link,
  localizedLinks: LinkMap
) => {
  if (currentPageLink.slugs.length < 2) return undefined;

  let parentArrayString: string[] | string = [...currentPageLink.slugs];
  if (
    currentPageLink.slugs.includes(PageType.FAQQuestion) &&
    currentPageLink.slugs[currentPageLink.slugs.length - 1] !==
      PageType.FAQQuestion
  ) {
    parentArrayString = [...parentArrayString.slice(0, 2)];
  } else {
    parentArrayString = [...parentArrayString.slice(0, 1)];
  }

  parentArrayString = JSON.stringify(parentArrayString);

  let returnLink: Link | undefined;
  Object.keys(localizedLinks).some((key) => {
    if (JSON.stringify(localizedLinks[key].slugs) === parentArrayString) {
      returnLink = localizedLinks[key];
      return true;
    }
  });
  return returnLink?.ref;
};

export enum PageType {
  ClubArticle = 'club',
  ClubGuide = 'guide',
  FAQQuestion = 'faq',
  TrafficInfo = 'traffic_information',
  Corporate = 'corporate',
  CookiePolicy = 'cookie_consent',
  News = 'news',
  NewsFull = 'news_with_pagination',
  Page = 'page',
}

export const refTypePages: Reference[] = [
  PageType.News,
  PageType.NewsFull,
  PageType.TrafficInfo,
  PageType.CookiePolicy,
];

const ClubArticle = dynamic(() =>
  import('../modules/Club/Article').then((mod) => mod.ClubArticle)
);
const Page = dynamic(() => import('../modules/Page').then((mod) => mod.Page));
const FAQQuestion = dynamic(() =>
  import('../modules/FAQ/Question').then((mod) => mod.FAQQuestion)
);
const TrafficInfo = dynamic(() =>
  import('../modules/TrafficInformation').then((mod) => mod.TrafficInformation)
);
const Corporate = dynamic(() =>
  import('../modules/Corporate').then((mod) => mod.Corporate)
);
const News = dynamic(() => import('../modules/News').then((mod) => mod.News));
const CookiePolicy = dynamic(() =>
  import('../modules/CookiePolicy').then((mod) => mod.CookiePolicy)
);

export const ReturnPageComponent = (id: string, pageType: PageType) => {
  switch (pageType) {
    case PageType.ClubArticle:
      return <ClubArticle id={id} isGuide={false} />;

    case PageType.ClubGuide:
      return <ClubArticle id={id} isGuide={true} />;

    case PageType.FAQQuestion:
      return <FAQQuestion id={id} />;

    case PageType.News:
      return <News id={id} isFullPressPage={false} />;

    case PageType.NewsFull:
      return <News id={id} isFullPressPage={true} />;

    case PageType.Corporate:
      return <Corporate id={id} />;

    case PageType.TrafficInfo:
      return <TrafficInfo id={id} />;

    case PageType.CookiePolicy:
      return <CookiePolicy id={id} />;

    default:
      return <Page id={id} />;
  }
};
